import axios from 'axios';

const axiosInstance = axios.create();
export const apiServ = process.env.REACT_APP_API_URL;
export const apiVersion = process.env.REACT_APP_API_VERSION;

const constructApiUrl = (url) => `${apiServ}/${apiVersion}/${url}`;

const headers = (data) => {
  const { accessToken, refreshToken } = data;
  const header = {
    headers: {
      access_token: accessToken,
      refreshToken,
      'Content-Type': 'application/json',
    },
  };
  return header;
};

const refresh = async (url, data) => {
  const urlAPI = constructApiUrl(url);
  try {
    const response = await axiosInstance.post(urlAPI, {}, headers(data));
    if (!response.headers || !response.headers.access_token) {
      throw new Error('Invalid refresh token response');
    }
    return response.headers;
  } catch (error) {
    throw new Error(`Refresh token failed: ${error.message}`);
  }
};

export default { refresh };
