import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, DatePicker } from 'antd';
import DashboardList from '../../shared/SharedStyle';
import TableTemplate from '../../shared/TableTemplate';
import { filterByMonth } from '../../../utils/utils';
import { getAnalysisAction } from '../../../business/actions/analysisAction';
import HeaderList from './HeaderList';

const AgencyDetailList = ({ agencyDetail, setCurrentMobile }) => {
  const { name, details } = agencyDetail;
  const { mobiles } = useSelector((state) => state.mobiles);
  const [tableData, setTableData] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(dayjs());
  const { t } = useTranslation();

  const renderData = () => {
    const data = [];
    if (details) {
      details.forEach((detail) => {
        const {
          leak, noLeak, qualificationOK, qualificationNOK, noSignal,
        } = detail;

        const total = filterByMonth(leak, selectedMonth).length
        + filterByMonth(noLeak, selectedMonth).length
        + filterByMonth(noSignal, selectedMonth).length;

        data.push({
          key: detail.telephoneNumber,
          telephoneNumber: detail.telephoneNumber,
          leak: filterByMonth(leak, selectedMonth).length,
          noLeak: filterByMonth(noLeak, selectedMonth).length,
          qualificationOK: filterByMonth(qualificationOK, selectedMonth).length,
          qualificationNOK: filterByMonth(qualificationNOK, selectedMonth).length,
          noSignal: filterByMonth(noSignal, selectedMonth).length,
          total,
        });
      });
      setTableData(data);
    }
  };

  useEffect(() => {
    renderData();
  }, [mobiles, selectedMonth]);

  const handleMonthChange = (date) => {
    setSelectedMonth(dayjs(date));
  };

  const handleSelectedMobile = (telephoneNumber) => {
    const selectedMobile = mobiles.find((mobile) => mobile.telephoneNumber === telephoneNumber);
    if (selectedMobile) {
      setCurrentMobile({
        telephoneNumber,
        androidId: selectedMobile.androidId,
        selectedMonth: selectedMonth.valueOf(),
      });
    }
  };

  const columns = [
    {
      title: t('dashboard.agencyDetail.numberMobile'),
      dataIndex: 'telephoneNumber',
      key: 'telephoneNumber',
      align: 'center',
      render: (text, record) => (
        <Button onClick={() => handleSelectedMobile(record.telephoneNumber)}>
          {text}
        </Button>
      ),
      responsive: ['md', 'lg'],
      width: 110,
    },
    {
      title: t('dashboard.agencyDetail.leak'),
      dataIndex: 'leak',
      key: 'leak',
      align: 'center',
      responsive: ['md', 'lg'],
      width: 90,
    },
    {
      title: t('dashboard.agencyDetail.noLeak'),
      dataIndex: 'noLeak',
      key: 'noLeak',
      align: 'center',
      responsive: ['md', 'lg'],
      width: 90,
    },
    {
      title: t('dashboard.agencyDetail.qualificationOK'),
      dataIndex: 'qualificationOK',
      key: 'qualificationOK',
      align: 'center',
      responsive: ['md', 'lg'],
      width: 90,
    },
    {
      title: t('dashboard.agencyDetail.qualificationNOK'),
      dataIndex: 'qualificationNOK',
      key: 'qualificationNOK',
      align: 'center',
      responsive: ['md', 'lg'],
      width: 90,
    },
    {
      title: t('dashboard.agencyDetail.noSignal'),
      dataIndex: 'noSignal',
      key: 'noSignal',
      align: 'center',
      responsive: ['md', 'lg'],
      width: 70,
    },
    {
      title: t('dashboard.agencyDetail.total'),
      dataIndex: 'total',
      key: 'total',
      align: 'center',
      responsive: ['md', 'lg'],
      width: 70,
    },
  ];

  return (
    <DashboardList width="800">
      <div className="agency-details-list">
        <HeaderList
          name={name}
          stateToUpdate={getAnalysisAction}
          visibleUpdateData
          isDetails
        />
        <span>{t('dashboard.agencyDetail.selectPeriod')}</span>
        <DatePicker
          defaultValue={selectedMonth}
          format="MM/YYYY"
          picker="month"
          disabledDate={(current) => current && current > dayjs().endOf('month')}
          onChange={handleMonthChange}
        />
      </div>
      <TableTemplate columns={columns} data={tableData} />
    </DashboardList>
  );
};

AgencyDetailList.propTypes = {
  agencyDetail: PropTypes.shape({
    name: PropTypes.string.isRequired,
    details: PropTypes.arrayOf(
      PropTypes.shape({
        telephoneNumber: PropTypes.string.isRequired,
        leak: PropTypes.arrayOf(PropTypes.number).isRequired,
        noLeak: PropTypes.arrayOf(PropTypes.number).isRequired,
        qualificationOK: PropTypes.arrayOf(PropTypes.number).isRequired,
        qualificationNOK: PropTypes.arrayOf(PropTypes.number).isRequired,
        noSignal: PropTypes.arrayOf(PropTypes.number).isRequired,
      }),
    ).isRequired,
  }).isRequired,
  setCurrentMobile: PropTypes.func.isRequired,
};

export default AgencyDetailList;
