import { put, takeEvery } from 'redux-saga/effects';
import { MONITOR, getMonitorAction } from '../actions/monitoringAction';

const getQualificationChart = (soundsList) => {
  const qualificationOK = soundsList.filter((sound) => {
    const noLeakSounds = sound.decision === 0 || sound.decision === 4;
    const noLeakQualification = sound.qualification === 0;
    return noLeakQualification === noLeakSounds;
  });
  const qualificationNOK = soundsList.filter((sound) => sound.qualification === 1);
  const qualificationNull = soundsList.filter((sound) => {
    const noQualification = sound.qualification === null || sound.qualification === 2;
    return noQualification;
  });
  const qualificationSounds = [
    qualificationOK.length,
    qualificationNOK.length,
    qualificationNull.length,
  ];
  return qualificationSounds;
};

export function* getMonitoring({ payload }) {
  let totalLeak = 0;
  let totalNoLeak = 0;
  let totalFaultySignal = 0;
  let totalAnalysis = 0;
  let totalSuspicionLeak = 0;
  let totalUnqualified = 0;
  let qualificationChart = [];
  if (payload) {
    const { soundsList } = payload;

    if (soundsList) {
      const leak = soundsList.filter((sound) => sound.decision === 1);
      const noLeak = soundsList.filter((sound) => sound.decision === 0);
      const noSignal = soundsList.filter((sound) => sound.qualification === 3);
      const unQualified = soundsList.filter((sound) => sound.qualification === 2);
      const suspicionLeak = soundsList.filter((sound) => sound.decision === 10);
      totalLeak = leak.length;
      totalNoLeak = noLeak.length;
      totalFaultySignal = noSignal.length;
      totalAnalysis = soundsList.length;
      totalSuspicionLeak = suspicionLeak.length;
      totalUnqualified = unQualified.length;
      qualificationChart = getQualificationChart(soundsList);
    }
  }
  try {
    yield put(getMonitorAction.success({
      leak: totalLeak,
      noLeak: totalNoLeak,
      faultySignal: totalFaultySignal,
      suspicionLeak: totalSuspicionLeak,
      analysis: totalAnalysis,
      unqualified: totalUnqualified,
      qualification: qualificationChart,
    }));
  } catch (error) {
    yield put(getMonitorAction.failure(error));
  }
}

export function* getMonitoringSaga() {
  yield takeEvery(MONITOR.FETCH.REQUEST, getMonitoring);
}
