import React, { useContext, useState } from 'react';
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { CloudSyncOutlined, CloudServerOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../utils/context/AuthContext';
import { getAnalysisDetailsAction } from '../../business/actions/analysisAction';

const UpdateData = ({ stateToUpdate, timeToRefresh, isDetails }) => {
  const { agencies } = useSelector((state) => state.agencies);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const { token, role } = useContext(AuthContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const renderUpdateBtnContent = () => {
    const textBtn = isRefreshing ? t('shared.updateData.updated') : t('shared.updateData.update');
    const iconBtn = isRefreshing ? <CloudServerOutlined className="green margin-left-medium" /> : <CloudSyncOutlined className="margin-left-medium" />;
    return (
      <>
        {textBtn}
        {iconBtn}
      </>
    );
  };

  const handleRefreshData = () => {
    setIsRefreshing(true);
    if (stateToUpdate) {
      if (isDetails) {
        dispatch(getAnalysisDetailsAction.success([]));
      }
      dispatch(stateToUpdate.request({ agencies, token, role }));
    }

    setTimeout(() => {
      setIsRefreshing(false);
    }, timeToRefresh);
  };

  return (
    <div className="update-data">
      <Button
        className="button-reset"
        disabled={isRefreshing}
        onClick={handleRefreshData}
      >
        {renderUpdateBtnContent()}
      </Button>
    </div>
  );
};

UpdateData.propTypes = {
  stateToUpdate: PropTypes.shape({
    request: PropTypes.func,
  }).isRequired,
  timeToRefresh: PropTypes.number,
  isDetails: PropTypes.bool,
};

UpdateData.defaultProps = {
  timeToRefresh: null,
  isDetails: false,
};

export default UpdateData;
